import { gql } from '@apollo/client'

const CREATE_TIP = gql`
  mutation CreateTip($input: CreateTipInput!) {
    createTip(input: $input) {
      _id
      createdAt
      title
      content
    }
  }
`

const UPDATE_TIP = gql`
  mutation UpdateTip($input: UpdateTipInput!) {
    updateTip(input: $input) {
      _id
      createdAt
      title
      content
    }
  }
`

const DELETE_TIP = gql`
  mutation DeleteTip($id: ID!) {
    deleteTip(id: $id)
  }
`

export { CREATE_TIP, UPDATE_TIP, DELETE_TIP }
