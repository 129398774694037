import { gql } from '@apollo/client'

// import { FRAGMENT_USER } from 'gql/fragments'

const IS_AUTHORIZED = gql`
  query IsUserAuthorized {
    isAuthorized @client
    user @client {
      _id
      firstName
      lastName
      email
      role
    }
  }
`

const LOGIN = gql`
  mutation signIn($input: UserSignIn!) {
    signIn(input: $input) {
      _id
      fullName
      firstName
      lastName
      nickName
      email
      role
      mobile
      countryCode
      isActive
      isVerified
      token
      deviceId
      oneSignalId
      versionCode
      notifyEmail
      notifySms
      location {
        type
        coordinates
      }
    }
  }
`

export { IS_AUTHORIZED, LOGIN }
