import 'rc-drawer/assets/index.css'
import React, { useState, useEffect } from 'react'
import DrawerMenu from 'rc-drawer'
import MenuLeft from './MenuLeft'

// const mapStateToProps = ({ settings }) => ({
//   isMenuTop: settings.isMenuTop,
//   isMobileMenuOpen: settings.isMobileMenuOpen,
//   isMobileView: settings.isMobileView,
//   isLightTheme: settings.isLightTheme,
// })

// @withRouter
export default function AppMenu(props) {
  const { role } = props

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isMobileView, setMobileView] = useState(window.innerWidth <= 500)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobileView(window.innerWidth <= 500)
    })
  }, [isMobileView])

  const toggleOpen = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
    setMobileView(window.innerWidth <= 500)

    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
  }

  const BootstrappedMenu = () => {
    if (isMobileView) {
      return (
        <DrawerMenu
          getContainer={null}
          level={null}
          open={isMobileMenuOpen}
          onMaskClick={toggleOpen}
          onHandleClick={toggleOpen}
        >
          <MenuLeft role={role} />
        </DrawerMenu>
      )
    }

    return <MenuLeft role={role} />
  }

  return BootstrappedMenu()
}
