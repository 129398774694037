import { gql } from '@apollo/client'

const GET_S3_SIGNED_URL = gql`
  mutation getS3SignedUrl($fileExt: String!, $fileType: String!) {
    getS3SignedUrl(fileExt: $fileExt, fileType: $fileType) {
      uploadUrl
      keyName
      s3Url
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { GET_S3_SIGNED_URL }
