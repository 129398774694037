import React, { useEffect } from 'react'
import { Modal, Button, Form, Input, Spin, notification } from 'antd'
import { useQuery, useMutation } from '@apollo/client'

import { GET_MY_PROFILE, UPDATE_PROFILE } from 'gql'

export default function ModalProfile(props) {
  const { visible, toggleModal, _id } = props

  const title = 'Profile Update'

  const [form] = Form.useForm()

  const { loading, data, error } = useQuery(GET_MY_PROFILE, { variables: { id: _id }, skip: !_id })
  if (error) {
    notification.error({
      message: 'Error',
      description: error,
    })
  }

  const [doUpdateProfile, { loading: loadingMutation }] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      notification.success({
        message: 'Success',
        description: 'Profile updated successfully!',
      })
      toggleModal(false)
    },

    onError(apolloError) {
      notification.error({
        message: 'Error',
        description: apolloError.message,
      })
    },
  })

  useEffect(() => {
    if (data && data.profile) form.setFieldsValue(data.profile)
  }, [data, form])

  function handleOk() {
    form
      .validateFields()
      .then(values => {
        const { firstName, lastName, currentPassword, password } = values
        const input = {
          _id,
          firstName,
          lastName,
          password,
          currentPassword,
        }
        doUpdateProfile({ variables: { input } })
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Please fix the error(s) and try again',
        })
      })
  }

  function handleCancel() {
    toggleModal(false)
  }

  return (
    <Modal
      title={title}
      visible={visible}
      confirmLoading={loadingMutation}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} loading={loadingMutation}>
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="modalProfileForm">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input email!' },
              { type: 'email', message: 'Please enter valid email' },
            ]}
          >
            <Input type="email" />
          </Form.Item> */}

          <Form.Item name="currentPassword" label="Change Password">
            <Input.Password />
          </Form.Item>

          <Form.Item name="password">
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve()

                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('The two passwords that you entered do not match!')
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
