import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { reduce } from 'lodash'

import { adminLeftMenuData } from 'services'
import styles from './style.module.scss'

/* export default function Breadcrumbs(props) {
  console.log('Breadcrumbs -> Breadcrumbs', props)
  const location = useLocation()

  const [breadcrumb, setBreadcrumb] = useState([])

  useEffect(() => {
    setBreadcrumbs()
  })

  const setBreadcrumbs = () => {
    console.log('setBreadcrumbs')
    setBreadcrumb(getBreadcrumb(location, adminLeftMenuData))
  }

  const getPath = (data, url, parents = []) => {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter(e => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  const getBreadcrumb = ({ pathname }, items) => {
    const [activeMenuItem, ...path] = getPath(items, pathname)

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={`${styles.arrow} text-muted`} />
              <span className="text-muted">{item.title}</span>
              <span className={styles.arrow} />
              <strong>{activeMenuItem.title}</strong>
            </span>
          )
        }

        return (
          <span key={item.key}>
            <span className={`${styles.arrow} text-muted`} />
            <span className="text-muted">{item.title}</span>
          </span>
        )
      })
    }

    return (
      <span>
        <span className={styles.arrow} />
        <strong>{activeMenuItem.title}</strong>
      </span>
    )
  }

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.path}>
        <Link to="/dashboard/alpha" className="text-muted">
          Home
        </Link>
        {breadcrumb}
      </div>
    </div>
  )
} */

@withRouter
export default class Breadcrumbs extends Component {
  state = { breadcrumb: [] }

  componentDidMount() {
    this.setBreadcrumbs(this.props)
  }

  componentWillReceiveProps(newProps) {
    this.setBreadcrumbs(newProps)
  }

  setBreadcrumbs = props => {
    this.setState({
      breadcrumb: this.getBreadcrumb(props, adminLeftMenuData),
    })
  }

  getPath = (data, url, parents = []) => {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = this.getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter(e => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  getBreadcrumb = ({ location }, items) => {
    const [activeMenuItem, ...path] = this.getPath(items, location.pathname)

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={`${styles.arrow} text-muted`} />
              <span className="text-muted">{item.title}</span>
              <span className={styles.arrow} />
              <strong>{activeMenuItem.title}</strong>
            </span>
          )
        }

        return (
          <span key={item.key}>
            <span className={`${styles.arrow} text-muted`} />
            <span className="text-muted">{item.title}</span>
          </span>
        )
      })
    }

    return (
      <span>
        <span className={styles.arrow} />
        <strong>{activeMenuItem.title}</strong>
      </span>
    )
  }

  render() {
    const { breadcrumb } = this.state

    return (
      <div className={styles.breadcrumbs}>
        <div className={styles.path}>
          <Link to="/users" className="text-muted">
            RP
          </Link>
          {breadcrumb}
        </div>
      </div>
    )
  }
}
