import { gql } from '@apollo/client'

const FRAGMENT_USER = gql`
  fragment UserInfo on Customer {
    _id
    fullName
    firstName
    lastName
    nickName
    email
    role
    mobile
    countryCode
    isActive
    isVerified
  }
`

// eslint-disable-next-line import/prefer-default-export
export { FRAGMENT_USER }
