import React, { useState } from 'react'
import { Menu, Dropdown, Avatar } from 'antd'

import { clearLocalStorage } from 'services'
import ModalProfile from 'components/CustomComponents/Modal/Profile'
import styles from './style.module.scss'

export default function ProfileMenu({ _id, role, email }) {
  const [modalVisible, setModalVisible] = useState(false)

  const logout = () => {
    clearLocalStorage()
  }

  function toggleModal(toggle) {
    setModalVisible(toggle)
  }

  const menu =
    role === 'ADMIN' ? (
      <Menu>
        <Menu.Item>
          <div>
            <strong>Email: </strong>
            {email}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a
            href="#0"
            onClick={event => {
              event.preventDefault()
              toggleModal(true)
            }}
          >
            <i className={`${styles.menuIcon} icmn-user`} />
            Profile
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="" onClick={logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        <Menu.Item>
          <div>
            <strong>Email: </strong>
            {email}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="" onClick={logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar src="resources/images/avatar.jpg" />
        </div>
      </Dropdown>

      {modalVisible && <ModalProfile visible={modalVisible} toggleModal={toggleModal} _id={_id} />}
    </>
  )
}
