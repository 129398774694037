import { gql } from '@apollo/client'

const UPLOAD_RESOURCE = gql`
  mutation SingleUpload($title: String!, $file: Upload!) {
    singleUpload(title: $title, file: $file) {
      uri
      filename
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { UPLOAD_RESOURCE }
