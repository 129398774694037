import { gql } from '@apollo/client'

const GET_RESOURCES = gql`
  query GetResources(
    $page: Int = 1
    $count: Int = 10
    $sort: String = "createdAt"
    $sortBy: Int = -1
    $title: String
  ) {
    getResources(page: $page, count: $count, sort: $sort, sortBy: $sortBy, title: $title) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        createdAt
        title
        fileName
        bucketUrl
      }
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { GET_RESOURCES }
