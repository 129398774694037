import React, { Fragment, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import { useQuery } from '@apollo/client'

// import { UserContext } from 'context'
import { IS_AUTHORIZED } from 'gql'

import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

export default function IndexLayout(props) {
  const { children } = props

  // const user = useContext(UserContext)
  // console.log('context:', user)

  const {
    // Since cache reads are synchronous, we don't have to account for any loading state.
    loading,
    data,
  } = useQuery(IS_AUTHORIZED)
  console.log('IndexLayout -> isAuthorized:', loading, data)

  const { pathname, search } = useLocation()

  let previousPath = ''

  useEffect(() => {
    if (pathname !== previousPath) {
      window.scrollTo(0, 0)
    }
  })

  // NProgress Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    NProgress.start()
  }

  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = (data && data.isAuthorized) || false
  const isUserLoading = loading
  const isLoginLayout = getLayout() === 'login'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <Loader />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    // redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && isUserAuthorized) {
      return <Redirect to="/users" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="RP | %s" title="React Admin Template" />
      {BootstrappedLayout()}
    </Fragment>
  )
}
