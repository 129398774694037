import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/link-context'

import { getToken } from 'services'
import { typeDefs, resolvers } from './resolvers'
import { IS_AUTHORIZED } from './mutations'

const cache = new InMemoryCache()

const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_RP_URI,
})

// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache,
  typeDefs,
  resolvers,
})

// cache.writeData({
//   data: {
//     isAuthorized: !!getToken(),
//     user: null,
//     me: null,
//   },
// })

cache.writeQuery({
  query: IS_AUTHORIZED,
  data: {
    isAuthorized: !!getToken(),
    user: null,
  },
})

// client.query({ query: GET_ME }).then(result => console.log(result))
