import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { ApolloProvider } from '@apollo/client'

import Router from 'router'
import * as serviceWorker from './serviceWorker'
import { client } from './gql'

// app styles
import './global.scss'

const history = createHashHistory()

const ApolloApp = () => (
  <ApolloProvider client={client}>
    <Router history={history} />
  </ApolloProvider>
)

ReactDOM.render(<ApolloApp />, document.getElementById('root'))

serviceWorker.register()
export default history
