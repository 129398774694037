import { gql } from '@apollo/client'

const GET_QUESTIONS = gql`
  query Questions(
    $page: Int = 1
    $count: Int = 10
    $question: String
    $questionNo: Int
    $type: String
  ) {
    getQuestions(
      page: $page
      count: $count
      question: $question
      questionNo: $questionNo
      type: $type
    ) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        createdAt
        type
        question
        hint
        guide
      }
    }
  }
`

const GET_QUESTION = gql`
  query Question($_id: ID!) {
    getQuestionById(_id: $_id) {
      _id
      createdAt
      updatedAt
      type
      question
      choices
      hint
      guide
    }
  }
`

export { GET_QUESTIONS, GET_QUESTION }
