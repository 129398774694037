import { gql } from '@apollo/client'

const CREATE_BLOG_POST = gql`
  mutation CreateBlogPost($input: createPostInput!) {
    createBlogPost(input: $input) {
      _id
      createdAt
      updatedAt
      createdBy {
        _id
        role
      }
      title
      bannerUrl
      description
      post
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { CREATE_BLOG_POST }
