import { gql } from '@apollo/client'

const GET_CONTACT_REQUESTS = gql`
  query GetContactRequests(
    $page: Int = 1
    $count: Int = 10
    $sort: String = "createdAt"
    $sortBy: Int = -1
    $mobile: String
    $email: String
    $name: String
  ) {
    getCallRequests(
      page: $page
      count: $count
      sort: $sort
      sortBy: $sortBy
      mobile: $mobile
      email: $email
      name: $name
    ) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        createdAt
        name
        surName
        mobile
        email
        message
      }
    }
  }
`

const GET_CONTACT_REQUEST = gql`
  query GetContactRequest($_id: ID!) {
    getCallRequestById(_id: $_id) {
      _id
      createdAt
      name
      surName
      mobile
      email
      message
    }
  }
`

export { GET_CONTACT_REQUESTS, GET_CONTACT_REQUEST }
