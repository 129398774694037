import { gql } from '@apollo/client'

const GET_BLOGS = gql`
  query GetBlogPosts($page: Int! = 1, $count: Int = 20, $_id: ID, $createdBy: ID) {
    getBlogPosts(page: $page, count: $count, _id: $_id, createdBy: $createdBy) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        createdAt
        title
        bannerUrl
        post
        description
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { GET_BLOGS }
