import { gql } from '@apollo/client'

const GET_LINECHARTS = gql`
  query LineCharts($teacherId: ID!, $classId: ID) {
    lineCharts(teacherId: $teacherId, classId: $classId) {
      id
      seriesData {
        label
        x_values
        y_values
      }
    }
  }
`

const GET_PIECHARTS = gql`
  query PieCharts($teacherId: ID!, $classId: ID) {
    pieCharts(teacherId: $teacherId, classId: $classId) {
      questionId
      questionNo
      question
      totalPoint
      labels
      values
    }
  }
`

const GET_PIECHART_HISTORY = gql`
  query PieChartHistory($teacherId: ID!, $classId: ID, $questionId: ID, $questionNo: Int) {
    pieChartHistory(
      teacherId: $teacherId
      classId: $classId
      questionId: $questionId
      questionNo: $questionNo
    ) {
      id
      seriesData {
        label
        x_values
        y_values
        z_values
      }
      type
      question
      choices
      questionNo
    }
  }
`

export { GET_LINECHARTS, GET_PIECHARTS, GET_PIECHART_HISTORY }
