import React from 'react'

import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

export default function TopBar(props) {
  const { pageTitle = 'Reflective Practitioner', _id = '', name = 'RP', role = '', email } = props

  return (
    <div className={styles.topbar}>
      <div className="mr-4 font-weight-bold">{pageTitle}</div>
      <div className="mr-auto" />
      <div className="mr-4 font-weight-bold">{name}</div>
      <ProfileMenu _id={_id} role={role} email={email} />
    </div>
  )
}
