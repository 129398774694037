import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

import { adminLeftMenuData } from 'services'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

// const mapStateToProps = ({ menu, settings }) => ({
//   menuData: menu.menuLeftData,
//   isMenuCollapsed: settings.isMenuCollapsed,
//   isMobileView: settings.isMobileView,
//   isSettingsOpen: settings.isSettingsOpen,
//   isLightTheme: settings.isLightTheme,
//   isMobileMenuOpen: settings.isMobileMenuOpen,
// })

export default function MenuLeft(props) {
  const { role } = props

  const leftMenu = adminLeftMenuData.filter(menu => menu.role.includes(role))

  const [isCollapsed, setCollapsed] = useState(false)
  const [isMobileView, setMobileView] = useState(false)

  useEffect(() => {
    setMobileView(isMobileView)
    setCollapsed(isCollapsed)
  }, [isMobileView, isCollapsed])

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, pro } = item

      if (item.divider) return <Divider key={Math.random()} />

      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </Link>
            )}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
          {pro && <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return leftMenu.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: 256,
        collapsible: false,
        collapsed: isCollapsed,
        onCollapse: setCollapsed,
      }
    : {
        width: 256,
        collapsible: true,
        collapsed: isCollapsed,
        onCollapse: setCollapsed,
        breakpoint: 'lg',
      }

  const menu = generateMenuItems()

  return (
    <Sider {...menuSettings} className={styles.menu}>
      <div className={styles.logo}>
        <div className={styles.logoContainer}>
          <img src={`resources/images/rp-logo-light${isCollapsed ? '-mobile' : ''}.png`} alt="" />
        </div>
      </div>
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...rest }) => (
          <div
            {...rest}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <Menu
          theme="dark"
          // onClick={handleClick}
          // selectedKeys={selectedKeys}
          // openKeys={openedKeys}
          // onOpenChange={onOpenChange}
          mode="inline"
          className={styles.navigation}
        >
          {menu}
        </Menu>
      </Scrollbars>
    </Sider>
  )
}
