import { gql } from '@apollo/client'

const CREATE_QUESTION = gql`
  mutation CreateQuestion($input: createQuestionInput!) {
    createQuestion(input: $input) {
      _id
      createdAt
      updatedAt
      type
      question
      choices
      hint
      guide
    }
  }
`

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($input: updateQuestionInput!) {
    updateQuestion(input: $input) {
      _id
      createdAt
      updatedAt
      type
      question
      choices
      hint
      guide
    }
  }
`

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($input: deleteQuestionInput!) {
    deleteQuestion(input: $input)
  }
`

export { CREATE_QUESTION, UPDATE_QUESTION, DELETE_QUESTION }
