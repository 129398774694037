const adminLeftMenuData = [
  {
    title: 'Home',
    key: 'home',
    url: '/my-reflection',
    icon: 'icmn icmn-home',
    role: ['MANAGER', 'TEACHER'],
  },
  {
    title: 'Users',
    key: 'users',
    url: '/users',
    icon: 'icmn icmn-user',
    role: ['ADMIN'],
  },
  {
    title: 'Teachers',
    key: 'teachers',
    url: '/teachers',
    icon: 'icmn icmn-user',
    role: ['MANAGER', 'TEACHER'],
  },
  {
    title: 'Reflections',
    key: 'reflections',
    url: '/reflections',
    icon: 'icmn icmn-delicious',
    role: ['ADMIN', 'MANAGER', 'TEACHER'],
  },
  {
    title: 'Questions',
    key: 'questions',
    url: '/questions',
    icon: 'icmn icmn-menu',
    role: ['ADMIN'],
  },
  {
    title: 'Blog Post',
    key: 'blog-post',
    url: '/blog/post',
    icon: 'icmn icmn-leaf',
    role: ['ADMIN'],
  },
  {
    title: 'Contact Requests',
    key: 'contact-requests',
    url: '/contact-requests',
    icon: 'icmn icmn-envelop',
    role: ['ADMIN'],
  },
  {
    title: 'Resources',
    key: 'resources',
    url: '/resources',
    icon: 'icmn icmn-file-text2',
    role: ['ADMIN', 'MANAGER', 'TEACHER'],
  },
]

async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Dashboard Alpha',
      key: 'dashboardAlpha',
      url: '/dashboard/alpha',
      icon: 'icmn icmn-home',
    },
  ]
}

export { adminLeftMenuData, getTopMenuData }
