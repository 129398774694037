import React from 'react'
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

// import { UserProvider } from 'context'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/auth/login',
    component: loadable(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot',
    component: loadable(() => import('pages/auth/forgot')),
    exact: true,
  },

  // Dashboards
  // {
  //   path: '/dashboard',
  //   component: loadable(() => import('pages/dashboard/alpha')),
  //   exact: true,
  // },

  {
    path: '/users',
    component: loadable(() => import('pages/user')),
    exact: true,
  },

  {
    path: '/teachers',
    component: loadable(() => import('pages/teacher')),
    exact: true,
  },

  {
    path: '/questions',
    component: loadable(() => import('pages/questions')),
    exact: true,
  },

  {
    path: '/contact-requests',
    component: loadable(() => import('pages/contact-request')),
    exact: true,
  },

  {
    path: '/reflections',
    component: loadable(() => import('pages/reflection')),
    exact: true,
  },
  {
    path: '/reflection/summary/:id',
    component: loadable(() => import('pages/reflection-summary')),
    exact: true,
  },

  {
    path: '/my-reflection',
    component: loadable(() => import('pages/last-reflection')),
    exact: true,
  },

  // Blog
  {
    path: '/blog/post',
    component: loadable(() => import('pages/blog/post')),
    exact: true,
  },
  {
    path: '/blog/add-blog-post',
    component: loadable(() => import('pages/blog/add-blog-post')),
    exact: true,
  },

  {
    path: '/resources',
    component: loadable(() => import('pages/resource')),
    exact: true,
  },
]

export default function Router() {
  return (
    <HashRouter>
      {/* <UserProvider> */}
      <IndexLayout>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          {routes.map(route => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
      {/* </UserProvider> */}
    </HashRouter>
  )
}
