import { gql } from '@apollo/client'

import { GET_ME } from 'gql/queries'
import { IS_AUTHORIZED } from 'gql/mutations'

const typeDefs = gql`
  type User {
    _id: ID
    fullName: String
    firstName: String
    lastName: String
    nickName: String
    email: String
    role: String
    mobile: String
    countryCode: String
    isActive: Boolean
    isVerified: Boolean
  }

  type Query {
    me: User
  }
`

const resolvers = {
  Query: {
    user: (_, args, { cache }) => {
      console.log('resolver -> query -> user:', cache)

      // query existing data
      const queryResult = cache.readQuery({
        query: IS_AUTHORIZED,
      })
      console.log('queryResult:', queryResult)
    },

    me: (_, args, { cache }) => {
      console.log('resolver -> query -> me:', cache)

      // query existing data
      const queryResult = cache.readQuery({
        query: GET_ME,
      })
      console.log('queryResult:', queryResult)
    },
  },
}

export { typeDefs, resolvers }
