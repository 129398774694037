import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

export default function LoginLayout({ children }) {
  return (
    <Layout>
      <Layout.Content>
        <div className={styles.layout}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link href="https://www.reflectivepractitioner.co.uk/">
                <img src="/resources/images/rp-logo.png" alt="RP" />
              </Link>
            </div>
          </div>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.footer} text-center`}>
            <p>Copyright &copy; 2021 Smart Intuition Ltd. All rights reserved.</p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
