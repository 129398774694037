import { gql } from '@apollo/client'

const GET_ME = gql`
  query ME {
    me @client {
      _id
      fullName
      firstName
      lastName
      nickName
      email
      role
      mobile
      countryCode
      isActive
      isVerified
    }
  }
`

const GET_MY_PROFILE = gql`
  query($_id: ID) {
    profile(_id: $_id) {
      _id
      fullName
      firstName
      lastName
      nickName
      email
      role
      mobile
      countryCode
      isActive
      isVerified
    }
  }
`

const GET_USER = gql`
  query user($_id: ID, $role: UserRole, $email: String, $mobile: String, $countryCode: String) {
    user(_id: $_id, role: $role, email: $email, mobile: $mobile, countryCode: $countryCode) {
      _id
      fullName
      firstName
      lastName
      nickName
      email
      role
      mobile
      countryCode
      isActive
      isVerified
      notifyEmail
      notifySms
      inviteCode
    }
  }
`

const GET_USERS = gql`
  query Users(
    $page: Int = 1
    $count: Int = 10
    $role: UserRole
    $mobile: String
    $countryCode: String
    $email: String
    $belongsTo: ID
    $inviteCode: String
  ) {
    users(
      page: $page
      count: $count
      role: $role
      mobile: $mobile
      countryCode: $countryCode
      email: $email
      belongsTo: $belongsTo
      inviteCode: $inviteCode
    ) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        firstName
        lastName
        role
        email
        isActive
        isVerified
        createdAt
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation EditUser($input: EditUserInput!) {
    editUser(input: $input) {
      _id
      fullName
      firstName
      lastName
      nickName
      inviteCode
      email
      role
      countryCode
      mobile
      isActive
      isVerified
      notifyEmail
      notifySms
    }
  }
`

const DELETE_USER = gql`
  mutation DeleteUser($_id: ID!) {
    deleteUser(_id: $_id)
  }
`

const GENERATE_INVITE_CODE = gql`
  mutation CreateInviteCode {
    createInviteCode {
      _id
      createdBy
      isActive
      usedBy
      createdAt
    }
  }
`

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: ProfileInput!) {
    updateProfile(input: $input) {
      _id
      fullName
      firstName
      lastName
      nickName
      email
      isActive
      role
      mobile
      countryCode
    }
  }
`

export {
  GET_ME,
  GET_MY_PROFILE,
  GET_USER,
  GET_USERS,
  UPDATE_USER,
  DELETE_USER,
  GENERATE_INVITE_CODE,
  UPDATE_PROFILE,
}
