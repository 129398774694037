import { gql } from '@apollo/client'

// GET_LATEST_REFLECTION
const GET_REFLECTIONS = gql`
  query GetReflections($input: filterReflectionsInput!) {
    getReflections(input: $input) {
      total
      page
      count
      data {
        _id
        createdAt
        classDate
        classInformation {
          _id
          className
          section
          subjectName
        }
        reflectedBy {
          firstName
          lastName
        }
        week
        achievedReflection
        achievedTarget
      }
    }
  }
`

const GET_REFLECTION = gql`
  query GetReflection($id: ID!) {
    getReflectionById(_id: $id) {
      _id
      createdAt
      classDate
      classInformation {
        _id
        className
        section
        subjectName
      }
      reflectedBy {
        _id
        firstName
        lastName
      }
      week
      achievedReflection
      achievedTarget
    }
  }
`

const GET_REFLECTION_TARGETS = gql`
  query GetReflectionTargetsByReflectionId($id: ID!) {
    getReflectionTargetsByReflectionId(reflectionId: $id) {
      _id
      reflectionId {
        createdAt
        week
        classInformation {
          className
          section
          subjectName
        }
      }
      targets {
        _id
        target
        status
      }
    }
  }
`

export { GET_REFLECTIONS, GET_REFLECTION, GET_REFLECTION_TARGETS }
