const getToken = () => localStorage.getItem('token')

const setToken = token => localStorage.setItem('token', token)

const removeToken = () => localStorage.removeItem('token')

const getUserId = () => localStorage.getItem('user')

const setUserId = id => localStorage.setItem('user', id)

const removeUserId = () => localStorage.removeItem('user')

const clearLocalStorage = () => localStorage.clear()

export { getToken, setToken, removeToken, getUserId, setUserId, removeUserId, clearLocalStorage }
