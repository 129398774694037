import { gql } from '@apollo/client'

const GET_TIPS = gql`
  query GetTips(
    $page: Int = 1
    $count: Int = 10
    $sort: String = "createdAt"
    $sortBy: Int = -1
    $title: String
    $content: String
  ) {
    getTips(
      page: $page
      count: $count
      sort: $sort
      sortBy: $sortBy
      title: $title
      content: $content
    ) {
      generatedAt
      generatedIn
      total
      page
      count
      data {
        _id
        createdAt
        title
        content
      }
    }
  }
`

const GET_TIP = gql`
  query GetTip($id: ID!) {
    getTip(id: $id) {
      _id
      createdAt
      title
      content
    }
  }
`

export { GET_TIPS, GET_TIP }
