import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    {/* <p>Copyright &copy; 2020 Smart Intuition Ltd. All rights reserved.</p> */}
    <div className={styles.copyright}>
      <span>
        © 2021
        <a
          href="http://app.reflectivepractitioner.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
        Smart Intuition Ltd.
        </a>
        <br />
        All rights reserved
      </span>
    </div>
  </div>
)

export default Footer
