import React from 'react'
import { BackTop, Layout } from 'antd'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'

import { useApolloClient, useQuery } from '@apollo/client'

import { IS_AUTHORIZED, GET_MY_PROFILE } from 'gql'
import { getUserId } from 'services'

export default function MainLayout(props) {
  const { children } = props

  const client = useApolloClient()
  const { user } = client.readQuery({ query: IS_AUTHORIZED })
  console.log('MainLayout -> client', user)

  const { _id = '', firstName = '', lastName = '', email = 'No email', role } = user || {}
  const name = `${firstName} ${lastName}`

  const { data, client: clientUseQuery } = useQuery(GET_MY_PROFILE, {
    variables: { _id: _id || getUserId() },
    skip: _id,
  })
  if (data) clientUseQuery.writeQuery({ query: IS_AUTHORIZED, data: { user: data.profile } })

  return (
    <Layout>
      <BackTop />
      <Menu role={role} />
      <Layout>
        <Layout.Header>
          <TopBar _id={_id} name={name} role={role} email={email} />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <Breadcrumbs />
          <div className="utils__content">{children}</div>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
